import axios from "axios";

export const smsMessage = async (id) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/sms/send`,{id},{
            headers:{
                "x-access-token":localStorage.getItem("token")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}