import axios from "axios";

export const mobileUpdate = async (mobile) => {
    console.log(mobile, "response")
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}/api/user/mobile/update`,
      { mobile: parseInt(mobile.mobile) },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err; // Throw the error instead of returning a message
  }
};
