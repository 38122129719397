import axios from "axios";
export const getStatus = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/almond/rewardstatus`, {},{
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}